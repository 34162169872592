<template>
  <div class="assign-project-banker fd-validation" :class="{ error: hasError }">
    <fd-form-section title="Assign Bankers">
      <banner
        v-if="hasError"
        class="col-12 bg-danger fg-white py-2"
        :dismissable="false"
      >
        Please assign at least 1 banker to this project.
      </banner>

      <!-- To Assign List -->
      <BankersToAssign
        class="col-12 md-col-6 px-3"
        :bankers="bankersAvailable"
        :isLoading="isBankerToAssignLoading"
        @search="searchBankerToAssign"
        @assign="assignBanker"
        @infinite-scroll="loadNextBankersToAssign"
      ></BankersToAssign>

      <!-- Assigned List -->
      <BankersAssigned
        class="col-12 md-col-6 px-3"
        :bankers="bankersAssigned"
        :isLoading="isBankersAssignedLoading"
        @remove="removeAssignedBanker"
        @infinite-scroll="loadNextBankersAssigned"
      >
      </BankersAssigned>
    </fd-form-section>
  </div>
</template>

<script>
import useValidator from "@/modules/General/composables/useValidator";
import useAssignToProject from "@/modules/Project/composables/useAssignToProject";

export default {
  setup(props, context) {
    const { validate, hasError, errorMessage } = useValidator(props);
    const {
      isToAssignLoading: isBankerToAssignLoading,
      toAssign: bankersToAssign,
      toAssignPagination: bankersToAssignPagination,

      isAssignedLoading: isBankersAssignedLoading,
      assigned: bankersAssigned,
      assignedPagination: bankersAssignedPagination,

      availableToAssign: bankersAvailable,

      searchToAssign: searchBankerToAssign,
      loadNextToAssign: loadNextBankersToAssign,
      loadNextAssigned: loadNextBankersAssigned,
      assignUser: assignBanker,
      removeAssigned: removeAssignedBanker,
      reloadAssigned: reloadAssignedBankers
    } = useAssignToProject("banker", { props, context });

    return {
      validate,
      hasError,
      errorMessage,
      // To Assign
      isBankerToAssignLoading,
      bankersToAssign,
      bankersToAssignPagination,
      // Assigned
      isBankersAssignedLoading,
      bankersAssigned,
      bankersAssignedPagination,

      bankersAvailable,
      // Methods
      searchBankerToAssign,
      loadNextBankersToAssign,
      assignBanker,
      removeAssignedBanker,
      loadNextBankersAssigned,
      reloadAssignedBankers
    };
  },
  components: {
    Banner: () => import("@/components/GlobalComponents/Banner"),
    BankersToAssign: () => import("./BankersToAssign"),
    BankersAssigned: () => import("./BankersAssigned")
  },
  mixins: [],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: [Number, String]
    },
    type: {
      type: String,
      validator: (val) => ["agency", "developer"].includes(val)
    },
    mapValue: {
      type: Function,
      default: (val) => val
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
